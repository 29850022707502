import React from 'react';
import { PROGRESS_STEPPER_STEPS_ENUM } from '../../../domain/constants/PROGRESS_STEPPER_STEPS_ENUM';
import { PaymentSessionState } from '../../../domain/models/states/PaymentSessionState';
import { getPaymentSession } from '../../../services/api/PaymentService';
import { loadStripe } from '@stripe/stripe-js';
import {
  ERROR_PAGE,
  SUCCESS_PAGE,
  SUMMARY_SCREEN,
  ENTER_PERSONAL_DETAILS_SCREEN
} from '../../../services/routers/Routes';
import ProgressSpinnerComponent from '@bsurance/bslib/src/components/ProgressSpinner/ProgressSpinnerComponent';
import history from '../../../services/routers/History';
import { getPurchaseById } from '../../../services/api/PurchaseService';
import { Redirect } from 'react-router';
import {
  manageApiError,
  redirectByPurchaseStatus
} from '../../../services/ApiHelperFunctions';

export class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = new PaymentSessionState();
  }

  componentDidMount() {
    this.props.onCurrentStep(PROGRESS_STEPPER_STEPS_ENUM.PAYMENT);
    this.setState({ isLoading: true });
    this.checkPurchaseStatus();
    this.fetchPaymentSession();
    window.scrollTo(0, 0);
  }

  checkPurchaseStatus() {
    this.setState({ isLoading: true });

    getPurchaseById(this.props.match.params.purchaseId)
      .then(purchase => {
        redirectByPurchaseStatus(
          purchase,
          this.state.toaster,
          `${SUCCESS_PAGE.path}/${this.props.match.params.purchaseId}`,
          ERROR_PAGE.path
        );
      })
      .catch(error => {
        manageApiError(error, this.state.toaster);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  fetchPaymentSession() {
    const payload = {
      type: 'stripe_checkout_session',
      metadata: {
        cancel_url: `${
          process.env.REACT_APP_API_URL
        }/${SUMMARY_SCREEN.path.substring(1)}/${
          this.props.match.params.purchaseId
        }/`,
        success_url: `${
          process.env.REACT_APP_API_URL
        }/${SUCCESS_PAGE.path.substring(1)}/${
          this.props.match.params.purchaseId
        }/`,
        locale: 'en'
      }
    };

    getPaymentSession(this.props.match.params.purchaseId, payload)
      .then(async result => {
        if (process.env.NODE_ENV === 'development') {
          setTimeout(() => {
            history.push(
              `${SUCCESS_PAGE.path}/${this.props.match.params.purchaseId}/`
            );
          }, 3000);
        } else {
          this.setState({
            paymentSession: result
          });

          let maxRetries = 5;

          for (let i = 0; i < maxRetries; i++) {
            try {
              const stripe = await loadStripe(
                result.metadata.public_api_key || ''
              );
              if (stripe) {
                const { error } = await stripe.redirectToCheckout({
                  sessionId: result.metadata.checkout_session_id || ''
                });
                if (error) {
                  console.log(error);
                } else {
                  return;
                }
              }
            } catch (error) {
              console.log(error);
            }
            await new Promise(r => setTimeout(r, 1000));
          }
          history.push(`${ERROR_PAGE.path}`);
        }
      })
      .catch(error => {
        if ('response' in error && error.response.status === 400) {
          this.setState({ errors: error.response.data });
        } else {
          // TODO Internal log here would be nice.
          history.push(`${ERROR_PAGE.path}`);
        }
      });
  }

  render() {
    return (
      <>
        <h1 className="p-5 text-center">Connecting to payment system...</h1>
        <ProgressSpinnerComponent isLoading={this.state.isLoading} />
        {this.state.errors ? (
          <Redirect
            to={{
              pathname: `${ENTER_PERSONAL_DETAILS_SCREEN.path}/${this.props.match.params.purchaseId}/`,
              state: { errors: this.state.errors }
            }}
          />
        ) : null}
      </>
    );
  }
}

export default Payment;
