import './index.scss';
import './i18n';

import * as Sentry from '@sentry/react';

import React, { Suspense } from 'react';

import App from './App';
import CookieConsent from '@bsurance/bslib/src/services/CookieConsent';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://72d29b090c634763ac2ebeb3fdbe6e49@o1054197.ingest.sentry.io/4504287342821376',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
    environment: process.env.REACT_APP_TARGET
  });
}

const tagManagerArgs = {
  gtmId: 'GTM-TLBG3CK',
  dataLayer: {
    userId: '001',
    userProject: 'oead-shop'
  }
};

ReactDOM.render(
  <Suspense fallback="loading">
    <App />
    <CookieConsent language={'en'} tagManagerArgs={tagManagerArgs} />
  </Suspense>,
  document.getElementById('root')
);
