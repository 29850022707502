import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';

export class SucessPageState {
  toaster;
  isLoading;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
  }
}
