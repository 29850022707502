import { notification } from 'antd';

const displayedMessages = new Set();

export default {
  success: function(msg) {
    this.toast(msg, 'success');
  },
  error: function(msg) {
    this.toast(msg, 'error');
  },
  warning: function(msg) {
    this.toast(msg, 'warning');
  },
  info: function(msg) {
    this.toast(msg, 'info');
  },
  toast: function(msg, variant) {
    if (displayedMessages.has(msg)) {
      console.log(`Message already displayed: ${msg}`);
      return; // Do not display the message if it's already been shown
    }

    notification[variant]({
      message: msg,
      placement: 'top'
    });

    displayedMessages.add(msg);

    // Optional: Remove the message from the set after a certain timeout
    // if you want the message to be able to be shown again after some time
    setTimeout(() => displayedMessages.delete(msg), 3000); // Adjust time as needed
  }
};
