import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { LANDING_PAGE } from '../services/routers/Routes';

const NotFound = props => (
  <div className="text-center p-5">
    <h1>{props.text ? props.text : 'Page Not Found!'}</h1>
    <div className="display-4 md:display-1 mt-5 mb-5">
      <FontAwesomeIcon icon={faFrown} />
    </div>

    <Link to={LANDING_PAGE.path}>Go Home</Link>
  </div>
);

export default NotFound;
