import React from 'react';
import { Form, FormControl, FormLabel, InputGroup } from 'react-bootstrap';
import InfoWrapper from './InfoWrapper';

/**
 * @deprecated
 * USE MyFormInputCurrency.tsx
 */
const FormInputCurrency = props => {
  const {
    value,
    placeholder,
    labelValue,
    id,
    styling,
    type,
    handleChange,
    handleBlur,
    as,
    isInvalid,
    errorMessage,
    isDisabled,
    infoMessage,
    infoPlacement
  } = props;

  return (
    <div className={styling}>
      <FormLabel className="font-weight-bold" htmlFor={id}>
        {labelValue}
        {infoMessage && !isDisabled ? (
          <InfoWrapper message={infoMessage} placement={infoPlacement} />
        ) : null}
      </FormLabel>
      <InputGroup>
        <FormControl
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type={type}
          as={as}
          isInvalid={isInvalid}
          disabled={isDisabled}
        />
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon1">€</InputGroup.Text>
        </InputGroup.Append>
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default FormInputCurrency;
