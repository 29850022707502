import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import fs_logo from './../static/img/fs_logo.png';
import oead_logo from './../static/img/oead_logo.jpg';
import oead_logo_mobile from '../static/img/fS_Logo_White_mobile.jpeg';
import uniqa_logo from './../static/img/uniqa_logo_blue.png';
import { Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CLAIM_PAGE, LANDING_PAGE } from '../services/routers/Routes';

const Header = () => {
  return (
    <Navbar variant="light" className="py-2" expand="lg">
      <Link to="/shop/">
        <Navbar.Brand>
          <Image
            width={100}
            alt="Uniqa Austria Insurance AG Logo"
            src={uniqa_logo}
            className="d-none d-lg-block"
          />{' '}
          <Image
            width={70}
            alt="Uniqa Austria Insurance AG Logo"
            src={uniqa_logo}
            className="d-lg-none"
          />{' '}
        </Navbar.Brand>
      </Link>

      <div className="d-flex flex-grow-1 justify-content-center align-items-center d-lg-none">
        <a
          href="https://www.oeadstudenthousing.at/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            width={70}
            alt="OeAD Studenthousing Logo"
            src={oead_logo}
            className="d-lg-none ml-4 mr-2"
          />
        </a>

        <p className="text-dark d-none d-sm-block pt-3 mr-2">
          in cooperation with
        </p>

        <a
          href="https://www.feelsafe.at"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            width={90}
            alt="feelsafe Logo"
            src={oead_logo_mobile}
            className="d-lg-none"
          />
        </a>
      </div>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="align-items-center">
          <a
            href="https://www.oeadstudenthousing.at/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              width={130}
              alt="OeAD Studenthousing Logo"
              src={oead_logo}
              className="d-none d-lg-block mr-4"
            />
          </a>
          <p className="text-dark d-none d-lg-block pt-3 mr-2">
            in cooperation with
          </p>
          <a
            href="https://www.feelsafe.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              width={70}
              alt="feelsafe Logo"
              src={fs_logo}
              className="d-none d-lg-block mr-5"
            />
          </a>
          <Link
            to={LANDING_PAGE.path}
            className="text-dark font-weight-bold mr-3"
          >
            Home
          </Link>
          <a href={CLAIM_PAGE.path} className="text-dark font-weight-bold mr-3">
            Report a Claim
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
