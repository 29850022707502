let yup = require('yup');
export const baseValidationSchema = yup.object().shape({
  price_amount: yup
    .number()
    .positive('Please enter an amount greater than 0!')
    .required('Required!')
    .typeError('Please enter a valid number!')
    .min(10, 'The insured amount must be a minimum of 10 euros!')
    .max(1000000, 'We only insure up to a sum of 1.000.000€')
});
