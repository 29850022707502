import { PulseLoader } from 'react-spinners';
import React from 'react';
import './ProgressSpinner.scss';

const ProgressSpinnerComponent = props => {
  return (
    <>
      {props.isLoading ? (
        <div className="overlay fixed-top w-100 h-100 ">
          <PulseLoader loading={true} />
        </div>
      ) : null}
    </>
  );
};

export default ProgressSpinnerComponent;
