import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';

export class QuoteState {
  toaster;
  isLoading;
  grossPremium;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
    this.grossPremium = '';
  }
}
