import { COUNTRY } from '../../constants/CONSTANTS';

export class Quote {
  type;
  customer_country;
  duration_amount;
  price;

  constructor(quoteView, durationAmount) {
    this.type = quoteView.type;
    this.price = { amount: quoteView.price_amount, currency: 'EUR' };
    this.duration_amount = durationAmount;
    this.customer_country = COUNTRY.AUSTRIA;
  }
}
