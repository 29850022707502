import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';

const Imprint = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="mb-4 mt-4">
      <Row>
        <Col className="backgroundLighter text-center">
          <CardDeck>
            <Card className="shadow-lg col-12">
              <Card.Title className="text-center mt-4">
                <h1>Imprint</h1>
              </Card.Title>
              <Card.Body className="text-justify">
                <p>Michael Herf GmbH</p>
                <p>
                  Insurance agency, General agency of UNIQA Austria Insurance AG
                </p>
                <p>
                  UID-Nr: ATU64623205FN: 321569 x GISA Nr.: 25717044FB-court:
                  (Commercial Court Vienna)
                </p>
                <p>
                  Registered office: 1010 Vienna B&auml;ckerstra&szlig;e 7/6 |
                  Austria
                </p>
                <p>
                  Mail:{' '}
                  <a
                    title="mailto:michael.herf@uniqa.at"
                    href="mailto:michael.herf@uniqa.at"
                  >
                    michael.herf@uniqa.at
                  </a>{' '}
                  member of the commercial chamber of Austria
                </p>
                <p>
                  Short title: Trade Regulations 1994 Publication body: BGBl.
                  Nr. 194/1994 last amended by BGBl. I Nr.
                  18/2015&sect;/Article/Annex: &sect; 137fEffective date:
                  27.03.2015
                </p>
                <p>Exercise principles, Information requirements</p>
                <p>
                  &sect; 137f. (1) Insurance intermediaries shall appear as such
                  in business transactions. The own papers and documents used in
                  the insurance mediation shall contain clearly visible in the
                  header or in the footer name and address, the GISA number and
                  the designation "insurance mediator".
                  <br />
                  (2) For insurance intermediaries exclusively in the form of
                  "insurance agent", paragraph 1 shall apply with the difference
                  that they shall act as such and papers and documents shall
                  contain clearly visible in the header or in the footer the
                  indication "insurance agent" and all agency relationships.
                  <br />
                  (3) For insurance intermediaries exclusively in the form of
                  "insurance broker and consultant in insurance matters",
                  subsection (1) shall apply, except that they shall act as such
                  and papers and documents shall contain clearly visible in the
                  header or in the footer the indication "insurance broker and
                  consultant in insurance matters".
                  <br />
                  (4) Traders who have the right to act as insurance brokers on
                  the basis of an authorization to act as commercial financial
                  advisors (Section 94(75)) shall clearly indicate in their
                  business dealings and on papers and documents, either in the
                  header or in the footer, that they are authorized to act as
                  insurance brokers for life and accident insurance. If the
                  activity is carried out exclusively in the form specified in
                  subsection 2 or in subsection 3, the notice shall take into
                  account, mutatis mutandis, subsection 2 or subsection 3.
                  <br />
                  (5) Traders who have registered the activity of insurance
                  mediation as a secondary trade or as a restricted trade shall
                  clearly indicate the secondary trade or the restricted trade
                  in the header or in the footer in business transactions and on
                  papers and documents. If the activity is carried out
                  exclusively in the form specified in subsection 2 or
                  subsection 3, the notice shall take into account subsection 2
                  or subsection 3 mutatis mutandis.
                  <br />
                  (6) If there is an authorization to receive premiums for the
                  insurance company or amounts intended for the customer, this
                  shall also be made clear in terms of paras. 1 to 5.
                  <br />
                  (7) The insurance intermediary shall be obliged to ensure that
                  the following information is given to the insurance customer
                  upon conclusion of each initial insurance contract and, if
                  necessary, upon amendment or renewal of the contract, before
                  the customer submits his contractual declaration:
                </p>
                <ol data-indent-level="1">
                  <li>
                    <p>his name and address;</p>
                  </li>
                  <li>
                    <p>
                      in which register he has been entered and by what means
                      the entry can be verified;
                    </p>
                  </li>
                  <li>
                    <p>
                      whether he/she holds a direct or indirect interest of more
                      than 10% in the voting rights or capital of a particular
                      insurance company;
                    </p>
                  </li>
                  <li>
                    <p>
                      whether a particular insurance company or its parent
                      company holds a direct or indirect interest of more than
                      10% of the voting rights or capital in its company;
                    </p>
                  </li>
                  <li>
                    <p>
                      information on complaint procedures concerning insurance
                      mediation.
                      <br />
                      (8) During a consultation, the insurance intermediary
                      shall act either in the form of "insurance agent" or in
                      the form of "insurance broker and consultant in insurance
                      matters". With regard to each individual contract offered,
                      the insurance intermediary shall, before the customer
                      makes a contractual declaration, inform the customer:
                      <br />
                      (1) whether he bases his advice on a balanced market
                      investigation in accordance with paragraph 9; or
                    </p>
                  </li>
                  <li>
                    <p>
                      whether he is contractually bound and either
                      <br />
                      (a) is obliged to conduct insurance mediation business
                      with respect to the insurance product covered by the
                      contract exclusively with an insurance undertaking.
                      <br />
                      In this case, upon request, he shall also inform the
                      customer of the names of any other insurance undertakings
                      to which he is contractually bound, in which case the
                      customer shall be informed of this right, or
                      <br />
                      b) although he is not obliged to conduct insurance
                      brokerage transactions with regard to the insurance
                      product covered by the contract exclusively with one
                      insurance undertaking, he does not base his advice on a
                      balanced market investigation (no. 1) due to his
                      contractual ties.
                      <br />
                      In this case, he shall also inform the customer of the
                      names of the insurance undertakings with which he may and
                      does conduct insurance business.
                      <br />
                      (9) If the insurance intermediary informs the customer
                      that he gives advice on the basis of an objective
                      investigation, he shall be obliged to base his advice on
                      an investigation within the meaning of Section 28(3) of
                      the Brokerage Act, Federal Law Gazette No. 262/1996, as
                      amended, of insurance contracts offered on the market. In
                      the case of para. 8 no. 2 lit. b, this shall apply to a
                      limited extent to the insurance contracts offered by the
                      insurance undertakings for which the insurance
                      intermediary is allowed to and does conduct insurance
                      business.
                    </p>
                  </li>
                </ol>
              </Card.Body>
            </Card>
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};

export default Imprint;
