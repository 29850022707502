import { CommonDetailsView } from './CommonDetailsView';
import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';

export class SummaryDetailsView extends CommonDetailsView {
  premiumAmount;
  price_amount;
  startDate;
  endDate;
  toaster;

  constructor(purchase = Object()) {
    super();
    this.populateData(purchase);
    this.toaster = new ToasterClass();
  }

  populateData(purchase) {
    if (purchase.metadata && purchase.metadata.policy) {
      this.startDate = this.setGenericValue(
        purchase.metadata.policy.start_date,
        this.startDate
      );
      this.endDate = this.setGenericValue(
        purchase.metadata.policy.end_date,
        this.endDate
      );
    }

    this.price_amount = purchase.quote.price.amount;

    if (purchase.quote.tariffs.length > 0)
      this.premiumAmount = purchase.quote.tariffs[0].premium.amount;
    else this.toaster.showGenericError();
  }
}
