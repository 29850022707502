import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';

export class PaymentSessionState {
  toaster;
  isLoading;
  paymentSession;
  errors;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
    this.errors = null;
  }
}
