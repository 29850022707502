import { Card } from 'react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ClickableDocumentCard(props) {
  const { docLink, docDisplayName, iconToDisplay, iconSize, styling } = props;
  return (
    <Card className="shadow-lg">
      <Card.Body className="text-center">
        <a rel="noopener noreferrer" href={docLink} target="_blank">
          <FontAwesomeIcon
            icon={iconToDisplay}
            size={iconSize}
            className={styling}
          />
          <br />
          {docDisplayName}
        </a>
      </Card.Body>
    </Card>
  );
}
