import { APIFormatHelper } from '@bsurance/bslib/src/utils/APIFormatHelper';

export class Residence extends APIFormatHelper {
  country;

  constructor(personalDetailsView) {
    super();

    this.country = personalDetailsView.country;
  }
}
