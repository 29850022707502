import * as React from 'react';
import { FormLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InfoWrapper from '@bsurance/bslib/src/components/FormInput/InfoWrapper';
import './FormDatePickerWithLabel.scss';

export class FormDatePickerWithLabel extends React.Component {
  props;

  constructor(props) {
    super(props);

    this.props = props;
    this.onHandleChange = this.onHandleChange.bind(this);
  }

  onHandleChange(dateValue) {
    this.props.form.setFieldValue(
      this.props.field ? this.props.field.name : this.props.name,
      dateValue
    );
  }

  render() {
    return (
      <div className={this.props.styling}>
        <FormLabel
          className={
            'font-weight-bold ' + (this.props.isInvalid ? 'is-invalid' : '')
          }
          htmlFor={this.props.name}
        >
          {this.props.required && !this.props.isDisabled
            ? this.props.labelValue + ' *'
            : this.props.labelValue}
          {this.props.infoMessage && !this.props.isDisabled ? (
            <InfoWrapper
              message={this.props.infoMessage}
              placement={this.props.infoPlacement}
            />
          ) : null}
        </FormLabel>

        <div className="input-group">
          <DatePicker
            id={this.props.id}
            name={this.props.field ? this.props.field.name : this.props.name}
            className={
              'form-control ' + (this.props.isInvalid ? 'is-invalid' : '')
            }
            selected={
              this.props.field ? this.props.field.value : this.props.value
            }
            onChange={
              !!this.props.handleChange
                ? this.props.handleChange
                : this.onHandleChange
            }
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            dateFormat={this.props.dateFormat}
            disabled={this.props.isDisabled}
            showYearDropdown={this.props.showYearDropdown}
            showMonthDropdown={this.props.showMonthDropdown}
            dropdownMode="select"
            onBlur={this.props.handleBlur}
            placeholderText={this.props.placeholder}
            locale={this.props.locale}
          />
        </div>
        {this.props.isInvalid ? (
          <div className="invalid-feedback">{this.props.errorMessage}</div>
        ) : null}
      </div>
    );
  }
}

export default FormDatePickerWithLabel;
