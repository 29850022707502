import React from 'react';
import { CardDeck } from 'react-bootstrap';
import { ClickableDocumentCard } from '@bsurance/bslib/src/components/ClickableDocumentCard';

export function LegalDocumentsComponent(props) {
  return (
    <CardDeck className={props.className}>
      <ClickableDocumentCard
        docLink="/shop/static/documents/IPID_OEAD_07072021.pdf"
        docDisplayName="Product Information Document (IPID)"
        iconToDisplay="file-pdf"
        iconSize="2x"
        styling="mb-1"
      />

      <ClickableDocumentCard
        docLink="/shop/static/documents/dat_protection_information.pdf"
        docDisplayName="Data protection information"
        iconToDisplay="file-pdf"
        iconSize="2x"
        styling="mb-1"
      />

      <ClickableDocumentCard
        docLink="/shop/static/documents/OeAD_Terms_and_Conditions_22062021_EN.pdf"
        docDisplayName="Terms & Conditions"
        iconToDisplay="file-pdf"
        iconSize="2x"
        styling="mb-1"
      />
    </CardDeck>
  );
}
