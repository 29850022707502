import './../Static/Base.scss';
import React from 'react';
import Alert from 'react-bootstrap/Alert';

// We use this to show a more human readable field names
const labelIt = (text, labelMapping) => {
  if (labelMapping) {
    return text in labelMapping ? labelMapping[text] : text;
  }
  return text;
};

const ProcessedErrors = props => {
  return (
    <ul>
      {props.errorData &&
        Object.keys(props.errorData).map((field, index) => {
          return (
            <li>
              {Array.isArray(props.errorData)
                ? props.errorData[index]
                : labelIt(field, props.labelMapping)}
              {props.errorData && !Array.isArray(props.errorData) && (
                <ProcessedErrors
                  errorData={props.errorData[field]}
                  labelMapping={props.labelMapping}
                ></ProcessedErrors>
              )}
            </li>
          );
        })}
    </ul>
  );
};

const DisplayDRFErrors = props => {
  const { responseData, labelMapping } = props;

  if (responseData.constructor !== Object) return null;

  return (
    <Alert variant="danger">
      <b>{'Please fix the following errors:'}</b>
      <ProcessedErrors
        errorData={responseData}
        labelMapping={labelMapping}
      ></ProcessedErrors>
    </Alert>
  );
};

export default DisplayDRFErrors;
