import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';

export class SummaryDetailsState {
  toaster;
  isLoading;
  isDataLoaded;
  bikeDetails;
  personalDetails;
  summaryDetails;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
    this.isDataLoaded = false;
  }
}
