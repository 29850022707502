import React from 'react';
import QuoteComponent from './Stepper/Quote/QuoteDetails';
import Header from './Header';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../services/routers/History';
import {
  ENTER_PERSONAL_DETAILS_SCREEN,
  IMPRINT_PAGE,
  LANDING_PAGE,
  SUCCESS_PAGE,
  SUMMARY_SCREEN,
  ENTER_PAYMENT_SCREEN,
  ERROR_PAGE
} from '../services/routers/Routes';
import Summary from './Stepper/Summary/Summary';
import Footer from './Footer';
import Imprint from './Imprint';
import PersonalDetails from './Stepper/PersonalData/PersonalDetails';
import ProcessStepper from './Stepper/ProcessStepper';
import SuccessPage from './Stepper/SuccessPage';
import NotFound from './NotFound';
import Payment from './Stepper/Payment/Payment';
import ErrorPage from './ErrorPage';

class OeadStore extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column AppWrapper">
        <Router history={history}>
          <Header />
          <div style={{ flex: 1 }}>
            <Switch>
              {/* Order is important, root should be at the bottom */}
              <Route
                exact
                path={ERROR_PAGE.match}
                render={() => <ErrorPage />}
              />
              <Route
                exact
                path={ENTER_PAYMENT_SCREEN.match}
                render={props => (
                  <ProcessStepper component={Payment} data={{}} {...props} />
                )}
              />
              <Route
                exact
                path={SUMMARY_SCREEN.match}
                render={props => (
                  <ProcessStepper component={Summary} data={{}} {...props} />
                )}
              />
              <Route exact path={IMPRINT_PAGE.match}>
                <Imprint />
              </Route>
              <Route
                exact
                path={SUCCESS_PAGE.match}
                render={props => (
                  <ProcessStepper
                    component={SuccessPage}
                    data={{}}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={ENTER_PERSONAL_DETAILS_SCREEN.match}
                render={props => (
                  <ProcessStepper
                    component={PersonalDetails}
                    data={{}}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={LANDING_PAGE.match}
                render={props => (
                  <ProcessStepper
                    component={QuoteComponent}
                    data={{}}
                    {...props}
                  />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default OeadStore;
