import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import '../Static/Base.scss';

const InfoWrapper = props => {
  const { message, placement } = props;

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement={props.placement || 'right'}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <FaInfoCircle
        className={props.iconStyle ? props.iconStyle : 'ml-1 info-circle'}
      />
    </OverlayTrigger>
  );
};

export default InfoWrapper;
