import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Form, Formik } from 'formik';

import PersonalDetails from '../PersonalData/PersonalDetails';
import FormInputCurrency from '@bsurance/bslib/src/components/FormInput/FormInputCurrency';
import ErrorFocus from '@bsurance/bslib/src/components/FormInput/ErrorFocus';
import { getPurchaseByIdWithEmbedEntity } from '../../../services/api/PurchaseService';
import { PersonalDetailsView } from '../../../domain/models/views/PersonalDetailsView';
import { SummaryDetailsState } from '../../../domain/models/states/SummaryDetailsState';
import history from '../../../services/routers/History';
import {
  ENTER_PAYMENT_SCREEN,
  ERROR_PAGE,
  SUCCESS_PAGE
} from '../../../services/routers/Routes';
import { ENTITY_TYPE_ENUM } from '@bsurance/bslib/src/domain/constants/ENTITY_TYPE_ENUM';
import { PROGRESS_STEPPER_STEPS_ENUM } from '../../../domain/constants/PROGRESS_STEPPER_STEPS_ENUM';
import { SummaryDetailsView } from '../../../domain/models/views/SummaryDetailsView';
import ProgressSpinnerComponent from '@bsurance/bslib/src/components/ProgressSpinner/ProgressSpinnerComponent';
import FormDatePickerWithLabel from '../../FormDatePickerWithLabel';
import { DATE_FORMAT } from '../../../domain/constants/CONSTANTS';
import {
  manageApiError,
  redirectByPurchaseStatus
} from '../../../services/ApiHelperFunctions';
import { userTokenIsValid } from '../../../services/api/UserTokenService';
import NotFound from '../../NotFound';

// TODO - Create tests when all payloads are created in the API
class Summary extends React.Component {
  constructor(props) {
    super(props);

    this.state = new SummaryDetailsState();
  }

  populateSummaryDetails() {
    this.setState({ isLoading: true });

    getPurchaseByIdWithEmbedEntity(
      this.props.match.params.purchaseId,
      ENTITY_TYPE_ENUM.QUOTE
    )
      .then(purchase => {
        redirectByPurchaseStatus(
          purchase,
          this.state.toaster,
          `${SUCCESS_PAGE.path}/${this.props.match.params.purchaseId}`,
          ERROR_PAGE.path
        );

        this.setState({
          personalDetails: new PersonalDetailsView(purchase),
          summaryDetails: new SummaryDetailsView(purchase),
          isDataLoaded: true
        });
      })
      .catch(error => {
        manageApiError(error, this.state.toaster);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  componentDidMount() {
    if (this.props.match) this.populateSummaryDetails();

    this.props.onCurrentStep(PROGRESS_STEPPER_STEPS_ENUM.SUMMARY);

    window.scrollTo(0, 0);
  }
  render() {
    if (this.state.isDataLoaded && userTokenIsValid()) {
      return (
        <Formik
          initialValues={{
            isCustomerAdult: '',
            isBikeNotOlderThen1Year: ''
          }}
          onSubmit={() => {
            history.push(
              `${ENTER_PAYMENT_SCREEN.path}/${this.props.match.params.purchaseId}/`
            );
          }}
          // validationSchema={summaryValidationSchema}
        >
          {() => (
            <Form id="summary">
              <ProgressSpinnerComponent isLoading={this.state.isLoading} />

              <h4 className="text-center mb-5">
                <strong>
                  Your OeAD student housing cancellation insurance
                </strong>
              </h4>

              <Container className="mb-5">
                <h4 className="my-5 font-weight-bolder">
                  Your insurance coverage
                </h4>
                <Row>
                  <Col sm="12" md="6">
                    <FormInputCurrency
                      id="premiumAmount"
                      value={this.state.summaryDetails.price_amount}
                      labelValue="Total insurance coverage:"
                      type="input"
                      styling="mb-5"
                      isDisabled={true}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <FormInputCurrency
                      id="premiumAmount"
                      value={this.state.summaryDetails.premiumAmount}
                      labelValue="Your Premium amount including insurance tax:"
                      type="input"
                      styling="mb-5"
                      isDisabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <FormDatePickerWithLabel
                      id="startDate"
                      dateFormat={DATE_FORMAT}
                      value={
                        this.state.summaryDetails.startDate &&
                        new Date(this.state.summaryDetails.startDate)
                      }
                      labelValue="Start date of insurance (today's date):"
                      styling="mb-5"
                      isDisabled
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <FormDatePickerWithLabel
                      id="startDate"
                      dateFormat={DATE_FORMAT}
                      value={
                        this.state.summaryDetails.endDate &&
                        new Date(this.state.summaryDetails.endDate)
                      }
                      labelValue="End date of insurance (your travel date):"
                      styling="mb-5"
                      isDisabled
                    />
                  </Col>
                </Row>
              </Container>
              <PersonalDetails
                data={this.state.personalDetails}
                isDisabled={true}
                match={null}
                onNextStep={() => {}}
                onCurrentStep={() => {}}
              />
              <ErrorFocus />
            </Form>
          )}
        </Formik>
      );
    } else if (!userTokenIsValid()) {
      return <NotFound text="You purchase is no longer available." />;
    } else {
      return <div />;
    }
  }
}

export default Summary;
