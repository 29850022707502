import axios from 'axios';
import Cookies from 'js-cookie';
import history from '../routers/History';
import { throwError } from 'rxjs';
import * as Sentry from '@sentry/react';

export class BaseService {
  apiClient;
  handleError;
  userResourceId;

  constructor(env, handleError) {
    this.handleError = handleError;
    this.userResourceId = '';

    let apiClientHeaders = {};

    if (env.token) {
      apiClientHeaders = { Authorization: 'Bearer ' + env.token };
    }

    this.apiClient = axios.create({
      baseURL: env.url,
      headers: apiClientHeaders,
      withCredentials: true
    });

    this.apiClient.interceptors.response.use(
      response => {
        return this.parseBody(response);
      },
      error => {
        Sentry.captureException(error);
        if (this.handleError && error) {
          return this.parseError(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  refreshAuth = async token => {
    return await this.apiClient.post(`/auth/token/refresh/`, {
      token: token
    });
  };

  setSourceHeader() {
    this.apiClient.defaults.headers['BS-Source'] = 'frontend';
  }

  setUserResourceId(userResourceId) {
    this.userResourceId = userResourceId;
    Cookies.set('user_id', userResourceId);
  }

  parseError(error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      return this.refreshAuth(Cookies.get('token') ?? '')
        .then(response => {
          if (response.token) {
            return this.apiClient(originalRequest);
          }
        })
        .catch(() => {
          history.push('/login');
          window.location.reload();
        });
    } else throwError('Generic Error');
  }

  parseBody(response) {
    let statusCode = response.status.toString();

    if (statusCode.startsWith('2') && statusCode.length === 3)
      return response.data;
    else return this.parseError(response.data.messages);
  }

  get(url, config) {
    return this.apiClient.get(url, config);
  }

  post(url, payload, optionalHeaders = {}) {
    return this.apiClient.post(url, payload, {
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        ...optionalHeaders
      }
    });
  }

  patch(url, payload, optionalHeaders = {}) {
    return this.apiClient.patch(url, payload, {
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        ...optionalHeaders
      }
    });
  }

  put(url, payload, optionalHeaders = {}) {
    return this.apiClient.put(url, payload, {
      headers: { 'X-CSRFToken': Cookies.get('csrftoken'), ...optionalHeaders }
    });
  }
}

const api = {
  url: process.env.REACT_APP_API_URL + '/v1',
  token: process.env.REACT_APP_TOKEN
};

const baseService = new BaseService(api, false);

export default baseService;
