import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROGRESS_STEPPER_STEPS_ENUM } from '../../domain/constants/PROGRESS_STEPPER_STEPS_ENUM';
import { SucessPageState } from '@bsurance/bslib/src/domain/models/states/SucessPageState';
import history from '../../services/routers/History';
import { ERROR_PAGE, LANDING_PAGE } from '../../services/routers/Routes';
import ProgressSpinnerComponent from '@bsurance/bslib/src/components/ProgressSpinner/ProgressSpinnerComponent';
import { getPurchaseById } from '../../services/api/PurchaseService';
import {
  manageApiError,
  redirectByPurchaseStatus
} from '../../services/ApiHelperFunctions';

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = new SucessPageState();
  }

  componentDidMount() {
    this.props.onCurrentStep(PROGRESS_STEPPER_STEPS_ENUM.SUCCESS);
  }

  checkPurchaseStatus() {
    this.setState({ isLoading: true });

    getPurchaseById(this.props.match.params.purchaseId)
      .then(purchase => {
        redirectByPurchaseStatus(
          purchase,
          this.state.toaster,
          undefined,
          ERROR_PAGE.path
        );
      })
      .catch(error => {
        manageApiError(error, this.state.toaster);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  goToQuote() {
    history.replace(LANDING_PAGE.path);
  }

  render() {
    return (
      <div id="successPage">
        <ProgressSpinnerComponent isLoading={this.state.isLoading} />

        <div className="display-4 md:display-1 mt-5 text-center">
          <FontAwesomeIcon icon="check-circle" />
        </div>
        <div className="display-4 md:display-1 mt-2 mb-5 font-weight-bolder text-center">
          Thank you!
        </div>
        {/* TODO: RE-enable this text as soon as Insly is integrated */}
        {/* <div className="lead mb-2 text-center">
          You will shortly receive a{' '}
          <span className="font-weight-bold">confirmation E-Mail</span> with
          your insurance details.
        </div> */}
        <div className="lead text-center">
          We wish you a successful stay in Austria!
        </div>

        <button
          onClick={this.goToQuote}
          className="btn btn-primary float-right mt-5"
          id="forward-button"
        >
          <span>
            <span>Done</span>
            <FontAwesomeIcon icon="arrow-right" className="ml-3" />
          </span>
        </button>
      </div>
    );
  }
}

export default SuccessPage;
