import baseService from '@bsurance/bslib/src/services/api/BaseSessionService';
import { validatePurchase } from './PurchaseService';

export const getPaymentSession = async (purchaseId, payload) => {
  // Validate purchase before creating payment
  try {
    await validatePurchase(purchaseId);
  } catch (error) {
    return Promise.reject(error);
  }

  let isDev = process.env.NODE_ENV === 'development';

  let endpoint = isDev
    ? '/_mocks/callbacks/stripe'
    : `/purchases/${purchaseId}/payment-session/`;

  return await baseService.post(endpoint, payload);
};
