import React from 'react';
import { Form, Formik } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FormInputCurrency from '@bsurance/bslib/src/components/FormInput/FormInputCurrency';
import { baseValidationSchema } from '../../../services/validators/BaseSchema';
import { createUserToken } from '../../../services/api/UserTokenService';
import { createQuote } from '../../../services/api/QuoteService';
import { Quote } from '../../../domain/models/api/Quote';
import { QuoteState } from '../../../domain/models/states/QuoteState';
import { TYPE_ENUM } from '../../../domain/constants/TYPE_ENUM';
import history from '../../../services/routers/History';
import { ENTER_PERSONAL_DETAILS_SCREEN } from '../../../services/routers/Routes';
import { POLICY_DURATION_ENUM } from '../../../domain/constants/POLICY_DURATION_ENUM';
import { BUSINESS_INFO_ENUM } from '../../../domain/constants/BUSINESS_INFO_ENUM';
import {
  createPurchase,
  linkPurchaseWithQuote
} from '../../../services/api/PurchaseService';
import { Purchase } from '../../../domain/models/api/Purchase';
import { PURCHASE_INSTANCE_TYPE_ENUM } from '../../../domain/constants/PURCHASE_INSTANCE_TYPE_ENUM';
import { PROGRESS_STEPPER_STEPS_ENUM } from '../../../domain/constants/PROGRESS_STEPPER_STEPS_ENUM';
import ProgressSpinnerComponent from '@bsurance/bslib/src/components/ProgressSpinner/ProgressSpinnerComponent';
import InfoWrapper from '@bsurance/bslib/src/components/FormInput/InfoWrapper';
import './QuoteDetails.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlane } from '@fortawesome/free-solid-svg-icons';
import StepNavigation from '../../StepNavigation/StepNavigation';

export class QuoteDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = new QuoteState();
  }

  componentDidMount() {
    this.props.onCurrentStep(PROGRESS_STEPPER_STEPS_ENUM.QUOTE);

    window.scrollTo(0, 0);
  }

  submitQuote(quoteData) {
    const promises = [];
    // const DURATION_AMOUNTS = [POLICY_DURATION_ENUM.ONE_YEAR, POLICY_DURATION_ENUM.THREE_YEAR];
    const DURATION_AMOUNTS = [POLICY_DURATION_ENUM.ONE_YEAR];

    DURATION_AMOUNTS.forEach(durationAmount => {
      promises.push(createQuote(new Quote(quoteData, durationAmount)));
    });

    try {
      this.setState({ isLoading: true });
      Promise.all(promises)
        .then(quotes => {
          const quote = quotes[0];
          createUserToken({})
            .then(() => {
              createPurchase(
                new Purchase(
                  quote.type,
                  quote,
                  PURCHASE_INSTANCE_TYPE_ENUM.PARTIAL_INSURED_ENTITY_POLICY
                )
              )
                .then(purchase => {
                  if (purchase) {
                    linkPurchaseWithQuote(purchase.purchase_id, quote.quote_id)
                      .then(() => {
                        history.push(
                          `${ENTER_PERSONAL_DETAILS_SCREEN.path}/${purchase.purchase_id}/`
                        );
                      })
                      .catch(error => {
                        this.handleSubmitErrors(error);
                      });
                  }
                })
                .catch(error => {
                  this.handleSubmitErrors(error);
                });
            })
            .catch(error => {
              this.handleSubmitErrors(error);
            });
        })
        .catch(error => {
          this.handleSubmitErrors(error);
        });
    } catch {
      //TODO: Include internal logs
      this.handleSubmitErrors();
    }
  }

  handleSubmitErrors(error) {
    if (error) {
      console.error(error);
    }

    this.state.toaster.showDanger(
      'Sorry, an error has occurred! Please try again later.'
    );
    this.setState({ isLoading: false });
  }

  isQuoteValid(quote) {
    if (quote.type) return true;

    this.state.toaster.showDanger(
      'Sorry, an error has occurred! Please try again later.'
    );
    return false;
  }

  render() {
    return (
      <Formik
        initialValues={{
          price_amount: 0,
          type: TYPE_ENUM.STUDENT_TRIP
        }}
        onSubmit={quoteData => {
          if (this.isQuoteValid(quoteData)) {
            this.submitQuote(quoteData);
          }
        }}
        validationSchema={baseValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched
        }) => (
          <Form onSubmit={handleSubmit} id="quote">
            <Col className="mt-5">
              <ProgressSpinnerComponent isLoading={this.state.isLoading} />

              <h4 className="text-center mb-5">
                <strong>OeAD student housing cancellation insurance</strong>
              </h4>

              <p className="text-center">
                The amount of the premium depends on the amount you want to
                insure.
                <br />
                Please calculate your potential loss of travel costs, such as
                deposit, transportation tickets and other costs, in case your
                trip is cancelled and enter the number below in EUR.
              </p>

              <Row className="align-items-center mt-5 flex-nowrap">
                <FontAwesomeIcon icon={faHome} size="3x" />
                <FontAwesomeIcon
                  icon={faPlane}
                  size="3x"
                  className="ml-3"
                  style={{ transform: 'rotate(-45deg)' }}
                />
                <p className="ml-4 mb-0">
                  Please select your desired coverage for your deposit, and
                  include, if you wish (optional), also your other travel
                  expenses
                  <InfoWrapper
                    message="What is covered: deposit and optionally also travel costs (flight, bus or train tickets)"
                    placement="top"
                  />
                </p>
              </Row>

              <Row className="justify-content-center mt-5 flex-column flex-lg-row">
                <FormInputCurrency
                  id="price_amount"
                  value={values.price_amount}
                  labelValue="Your desired coverage *"
                  placeholder="500"
                  type="number"
                  handleChange={e => {
                    handleChange(e);

                    const number_val = Number(e.currentTarget.value);

                    if (number_val && number_val > 0) {
                      const grossPremium = (number_val * 0.075).toFixed(2);

                      this.setState({ grossPremium });
                    }
                  }}
                  handleBlur={handleBlur}
                  isInvalid={touched.price_amount && errors.price_amount}
                  errorMessage={errors.price_amount}
                  infoMessage={BUSINESS_INFO_ENUM.SUM_INSURED}
                  styling="mb-4 mb-lg-0 w-md-100"
                  infoPlacement="top"
                />

                <FormInputCurrency
                  id="grossPremium"
                  value={this.state.grossPremium}
                  labelValue="Your quote"
                  placeholder="0"
                  styling="mx-auto w-md-100"
                  isDisabled
                />

                <StepNavigation form="quote" proceedLabel="Next" />
              </Row>

              <Row>
                <Col
                  className="p-3 mt-5"
                  style={{ backgroundColor: '#f2f8ff' }}
                >
                  <h5 className="text-center mb-2">
                    <strong>Coverage examples</strong>
                  </h5>
                  <p>Example: 1</p>
                  <p>
                    You want to insure your deposit in the amount of EUR 990.-
                    <br />
                    Please enter this amount in the desired coverage field.
                  </p>
                  <p>
                    Your total premium one-time payment is 7,5% of your selected
                    coverage, which equals EUR 74,25.-
                  </p>
                  <p>
                    In case you cannot start your trip because you are in
                    COVID-19 quarantine in your home country, you will get the
                    full amount of EUR 990.- refunded.
                  </p>

                  <p className="mt-4">Example: 2</p>
                  <p>
                    You want to insure your deposit in the amount of EUR 990.-
                    <br />
                    You also want to insure your flight ticket: EUR 1.500.-
                  </p>
                  <p>
                    You want to insure your deposit of EUR 990.- and your flight
                    ticket of EUR 1500.-
                    <br />
                    Please enter the amount of EUR 2490.- in the coverage field.
                    <br />
                    Your total premium one-time payment is 7,5% of your selected
                    coverage, which equals EUR 186,75.-
                  </p>
                  <p>
                    In case you cannot start your trip because of a sudden
                    illness, you will get the full amount of EUR 2490.-
                    refunded.
                  </p>
                </Col>
              </Row>
              <h5 className="text-center mb-2 mt-5">
                <strong>Coverage details</strong>
              </h5>
              <p className="mb-5">
                OeAD student housing cancellation insurance protects you against
                the loss of your paid deposit and optionally also other travel
                costs in the following circumstances:
                <ul>
                  <li>
                    Accident or sudden illness (including quarantine in your
                    home country due to COVID-19) that prevents you from
                    traveling to Austria
                  </li>
                  <li>
                    Own death or death of a family member (children, spouse,
                    parents or parents-in-law, as well as bride and groom or
                    cohabiting partner)
                  </li>
                  <li>Life-threatening illness</li>
                  <li>
                    Introduction of a travel ban or change of travel warning
                    status that prevents you from traveling to Austria to begin
                    your studies on the planned start date
                  </li>
                </ul>
              </p>

              <p className="mt-5">What is not insured?</p>
              <p>
                X if the reason for the trip cancellation already existed or was
                foreseeable
                <br />
                X pregnancy and pregnancy complications
                <br />
                X consequences of an accident, if the accident happens before
                the insurance starts and these consequences are not foreseeable
                <br />X in cause of job relation
              </p>

              <p>Are there any restrictions on cover?</p>
              <p className="mb-5">
                ! if the living space or the trip can be cancelled without costs
                and this possibility is not used (e.g.failure to observe the
                time-limit)
                <br />
                ! costs for ahead of time departure
                <br />
                ! shifting the date off the trip
                <br />! ordinance of quarantine by the responsible authority in
                Austria
              </p>
            </Col>
          </Form>
        )}
      </Formik>
    );
  }
}

export default QuoteDetails;
