import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import './HorizontalStepper.scss';
import { withTranslation } from 'react-i18next';

const HorizontalStepper = props => {
  return (
    <div className="w-100">
      <Stepper activeStep={props.activeStep} nonLinear>
        {props.steps.map(label => (
          <Step key={props.t(label)}>
            <StepLabel>{props.t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withTranslation(['translation'])(HorizontalStepper);
