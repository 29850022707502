import { APIFormatHelper } from '@bsurance/bslib/src/utils/APIFormatHelper';

export class InsuredEntity extends APIFormatHelper {
  price_amount;
  start_date;
  rental_start_date;

  constructor(personalDetailsView) {
    super();

    this.start_date = this.formatDate(personalDetailsView.travelStartDate);
    this.rental_start_date = this.formatDate(
      personalDetailsView.rentalAgreementStartDate
    );
  }
}
