import baseService from './BaseService';
import baseSessionService from './BaseSessionService';
import Cookies from 'js-cookie';

export default class AuthenticationService {
  authentication;
  customer_id;

  constructor(authentication) {
    this.authentication = authentication;
  }

  authenticate() {
    return baseService
      .post(`/auth/token/`, this.authentication)
      .then(response => {
        this.customer_id = response.customer_id;
      });
  }

  authTokens() {
    return baseService
      .post(`/auth/tokens/`, this.authentication)
      .then(response => {
        this.customer_id = response.customer_id;
        if (!response.token) {
          throw new Error('Auth token not found!');
        }
        Cookies.set('user_id', response.user_id);
        return response;
      });
  }

  async authUserTokens(user_id) {
    return await baseService
      .post(`/users/${user_id}/tokens/`, this.authentication)
      .then(response => {
        if (!response.token) {
          throw new Error('Auth token not found!');
        }
        return response;
      });
  }

  requestOneTimePassword() {
    return baseService.post(`/auth/one-time-passwords/`, this.authentication);
  }
}

export const isUserAuthenticated = () => {
  return baseSessionService
    .get(`/users/me/`)
    .then(() => {
      return true;
    })
    .catch(error => {
      return false;
    });
};

export const me = () => {
  return baseSessionService.get(`/users/me/`);
};

export const logout = () => {
  return baseSessionService
    .post(`/users/logout/`)
    .then(() => {
      return true;
    })
    .catch(error => {
      return false;
    });
};
