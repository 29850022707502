import { TFunction } from 'i18next';

export const PhonenumberValidationSchema = t => {
  let yup = require('yup');

  return yup.object().shape({
    phone: yup
      .string()
      .required(t('common:validation.required'))
      .matches('^\\+?1?\\d{9,15}$', t('common:validation.phone-length'))
  });
};
