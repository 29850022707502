import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Field, Form, Formik } from 'formik';
import FormInputWithLabel from '@bsurance/bslib/src/components/FormInput/FormInputWithLabel';
import FormSelectWithLabel from '@bsurance/bslib/src/components/FormInput/FormSelectWithLabel';
import ErrorFocus from '@bsurance/bslib/src/components/FormInput/ErrorFocus';
import DisplayDRFErrors from '@bsurance/bslib/src/components/DRFErrors/DRFErrors';
import { PersonalDetailsValidationSchema } from './PersonalDetailsValidationSchema';
import {
  DATE_FORMAT,
  MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE
} from '../../../domain/constants/CONSTANTS';
import { PersonalDetailsState } from '../../../domain/models/states/PersonalDetailsState';
import {
  getPurchaseById,
  updatePurchase
} from '../../../services/api/PurchaseService';
import { PROGRESS_STEPPER_STEPS_ENUM } from '../../../domain/constants/PROGRESS_STEPPER_STEPS_ENUM';
import { PersonalDetailsView } from '../../../domain/models/views/PersonalDetailsView';
import { BUSINESS_INFO_ENUM } from '../../../domain/constants/BUSINESS_INFO_ENUM';
import FormDatePickerWithLabel from '../../FormDatePickerWithLabel';
import { Purchase } from '../../../domain/models/api/Purchase';
import { PURCHASE_INSTANCE_TYPE_ENUM } from '../../../domain/constants/PURCHASE_INSTANCE_TYPE_ENUM';
import history from '../../../services/routers/History';
import {
  ERROR_PAGE,
  SUCCESS_PAGE,
  SUMMARY_SCREEN
} from '../../../services/routers/Routes';
import restCountries from '@bsurance/bslib/src/services/countries/RestCountries';
import { LegalDocumentsComponent } from '../../LegalDocumentsComponent';
import ProgressSpinnerComponent from '@bsurance/bslib/src/components/ProgressSpinner/ProgressSpinnerComponent';
import './PersonalDetails.scss';
import apiErrorsLabelMapping from './personal_details_api_errors_labels.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlane } from '@fortawesome/free-solid-svg-icons';
import {
  manageApiError,
  redirectByPurchaseStatus
} from '../../../services/ApiHelperFunctions';
import { withTranslation } from 'react-i18next';
import {
  userTokenIsValid,
  updateUserEmail
} from '../../../services/api/UserTokenService';
import NotFound from '../../NotFound';

class PersonalDetails extends React.Component {
  _today = new Date();

  constructor(props) {
    super(props);

    this.state = new PersonalDetailsState();
    this.initFill = this.initFill.bind(this);
  }

  componentDidMount() {
    if (this.props.match) this.populatePersonalDetails();
    if (this.props.data) this.setState({ personalDetails: this.props.data });
    if (this.props.errors) this.setState({ errors: this.props.errors });
    this.props.onCurrentStep(PROGRESS_STEPPER_STEPS_ENUM.PERSONAL_DETAILS);
    this.populateCountries();
    window.scrollTo(0, 0);
  }

  populateCountries() {
    restCountries.getAll().then(result => {
      const countries = [];

      for (const val of result) {
        countries.push({
          value: val.alpha2Code.toLowerCase(),
          display: val.name
        });
      }

      this.setState({
        countries
      });
    });
  }

  get maxDate() {
    return this._today;
  }

  get today() {
    return this._today;
  }

  get minTravelStartDate() {
    let minStartDate = new Date(this._today);
    minStartDate.setDate(
      this._today.getDate() + MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE
    );
    return minStartDate;
  }

  populatePersonalDetails() {
    this.setState({ isLoading: true });

    getPurchaseById(this.props.match.params.purchaseId)
      .then(purchase => {
        redirectByPurchaseStatus(
          purchase,
          this.state.toaster,
          `${SUCCESS_PAGE.path}/${this.props.match.params.purchaseId}`,
          ERROR_PAGE.path
        );

        this.setState({ personalDetails: new PersonalDetailsView(purchase) });
      })
      .catch(error => {
        manageApiError(error, this.state.toaster);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  submitPurchase(purchase) {
    this.setState({ isLoading: true });

    if (purchase.metadata.customer)
      updateUserEmail(purchase.metadata.customer.email);

    updatePurchase(this.props.match.params.purchaseId, purchase)
      .then(purchase => {
        redirectByPurchaseStatus(
          purchase,
          this.state.toaster,
          `${SUCCESS_PAGE.path}/${this.props.match.params.purchaseId}`,
          ERROR_PAGE.path
        );

        history.push(
          `${SUMMARY_SCREEN.path}/${this.props.match.params.purchaseId}/`
        );
      })
      .catch(error => {
        manageApiError(error, this.state.toaster);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  initFill() {
    if (this.props.fillForm === true) {
      return {
        isHousingDeposit: this.state.personalDetails.isHousingDeposit,
        isCancellationCost: true,
        rentalAgreementStartDate: new Date(),
        travelStartDate: new Date().setDate(new Date().getDate() + 30),
        firstName: 'First name',
        familyName: 'Family name',
        birthday: new Date().setFullYear(new Date().getFullYear() - 20),
        phone: '+421901234678',
        country: 'at',
        email: 'email@email.com',
        emailRepeat: 'email@email.com'
      };
    } else {
      return {
        isHousingDeposit: this.state.personalDetails.isHousingDeposit,
        // isCancellationCost: this.state.personalDetails.isCancellationCost,
        isCancellationCost: true,
        rentalAgreementStartDate: this.state.personalDetails
          .rentalAgreementStartDate,
        travelStartDate: this.state.personalDetails.travelStartDate,
        firstName: this.state.personalDetails.firstName,
        familyName: this.state.personalDetails.familyName,
        birthday: this.state.personalDetails.birthday,
        phone: this.state.personalDetails.phone,
        country: this.state.personalDetails.country,
        email: this.state.personalDetails.email,
        emailRepeat: this.state.personalDetails.emailRepeat
      };
    }
  }

  render() {
    if (this.state.personalDetails && userTokenIsValid()) {
      return (
        <Formik
          initialValues={this.initFill()}
          onSubmit={data => {
            try {
              this.submitPurchase(
                new Purchase(
                  this.state.type,
                  data,
                  PURCHASE_INSTANCE_TYPE_ENUM.CUSTOMER
                )
              );
            } catch (error) {
              this.state.toaster.showGenericError();
            }
          }}
          validationSchema={PersonalDetailsValidationSchema(this.props.t)}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            errors
          }) => (
            <Form id="personalDetails" onSubmit={handleSubmit}>
              <ProgressSpinnerComponent isLoading={this.state.isLoading} />

              {this.props.isDisabled ? (
                ''
              ) : (
                <h4 className="text-center mb-5">
                  <strong>OeAD student housing cancellation insurance</strong>
                </h4>
              )}

              {this.state.errors && (
                <>
                  <DisplayDRFErrors
                    responseData={this.state.errors}
                    labelMapping={apiErrorsLabelMapping}
                  />
                </>
              )}

              <Container className="mb-5">
                <div className="mt-5">
                  {!this.props.isDisabled && (
                    <>
                      {/* <h3 className="mt-5 font-weight-bolder">
                        The OeAD Studenthousing Insurance is something for you
                        if you:
                      </h3> */}

                      {/* <Field
                        id="isHousingDeposit"
                        name="isHousingDeposit"
                        text="have to pay a housing deposit and want to insure this in case of cancellation of your trip."
                        styling="h5 legalCheckbox custom-cb-size"
                        component={CheckboxWithText}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.isHousingDeposit}
                        isInvalid={
                          touched.isHousingDeposit && errors.isHousingDeposit
                        }
                        errorMessage={errors.isHousingDeposit}
                      />
                      <Field
                        id="isCancellationCost"
                        name="isCancellationCost"
                        text="have any cancellation costs (tickets, visa costs) to insure in case of cancellation of your trip."
                        styling="h5 legalCheckbox custom-cb-size"
                        component={CheckboxWithText}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.isCancellationCost}
                        isInvalid={
                          touched.isCancellationCost &&
                          errors.isCancellationCost
                        }
                        errorMessage={errors.isCancellationCost}
                        isDisabled={this.props.isDisabled}
                      /> */}
                    </>
                  )}
                </div>

                <h4 className="mb-4 mt-5 font-weight-bolder">
                  Booking Information
                </h4>
                <Row>
                  <Col sm="12" md="6">
                    <Row className="align-items-center flex-nowrap">
                      <FontAwesomeIcon
                        icon={faHome}
                        size="4x"
                        className="mb-3 mr-3"
                      />
                      <Field
                        name="rentalAgreementStartDate"
                        id="rentalAgreementStartDate"
                        component={FormDatePickerWithLabel}
                        styling="mb-4"
                        labelValue="My booking starts on"
                        required={true}
                        minDate={this.today}
                        dateFormat={DATE_FORMAT}
                        value={values.rentalAgreementStartDate}
                        isInvalid={
                          touched.rentalAgreementStartDate &&
                          errors.rentalAgreementStartDate
                        }
                        isDisabled={this.props.isDisabled}
                        errorMessage={errors.rentalAgreementStartDate}
                      />
                    </Row>
                  </Col>

                  <Col sm="12" md="6">
                    <Row className="align-items-center flex-nowrap">
                      <FontAwesomeIcon
                        icon={faPlane}
                        size="4x"
                        className="mb-3 mr-3"
                        style={{ transform: 'rotate(-45deg)' }}
                      />
                      <Field
                        name="travelStartDate"
                        id="travelStartDate"
                        component={FormDatePickerWithLabel}
                        styling="mb-4"
                        labelValue="I will start my travel to Austria on"
                        required={true}
                        minDate={this.minTravelStartDate}
                        dateFormat={DATE_FORMAT}
                        value={values.travelStartDate}
                        isInvalid={
                          touched.travelStartDate && errors.travelStartDate
                        }
                        isDisabled={this.props.isDisabled}
                        infoMessage={
                          'The insurance may be taken out either at the same time or after your booking. Starting 14 days before the beginning of your trip, the insurance must be taken out at the same time as the booking.'
                        }
                        errorMessage={errors.travelStartDate}
                        infoPlacement="top"
                      />
                    </Row>
                  </Col>
                </Row>
                {this.props.isDisabled ? (
                  <h6 className="my-3">
                    After successful payment of the insurance premium, you will
                    receive all insurance documents at the provided email
                    address. Your insurance coverage will end automatically the
                    day you begin your trip. There is no need to cancel the
                    contract and you will not be charged any additional fees.
                  </h6>
                ) : (
                  ''
                )}

                <h4 className="mb-4 mt-5 font-weight-bolder">
                  Personal Details
                </h4>
                <Row>
                  <Col sm="12" md="6">
                    <FormInputWithLabel
                      id="firstName"
                      value={values.firstName}
                      labelValue="First name"
                      required={true}
                      type="input"
                      styling="mb-5"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isInvalid={touched.firstName && errors.firstName}
                      errorMessage={errors.firstName}
                      isDisabled={this.props.isDisabled}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <FormInputWithLabel
                      id="familyName"
                      value={values.familyName}
                      labelValue="Family name"
                      required={true}
                      type="input"
                      styling="mb-5"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isInvalid={touched.familyName && errors.familyName}
                      errorMessage={errors.familyName}
                      isDisabled={this.props.isDisabled}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <FormInputWithLabel
                      id="phone"
                      value={values.phone}
                      labelValue="Phone number"
                      required={true}
                      type="input"
                      styling="mb-5"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isInvalid={touched.phone && errors.phone}
                      errorMessage={errors.phone}
                      isDisabled={this.props.isDisabled}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <FormSelectWithLabel
                      id="country"
                      labelValue="Country of residence"
                      required={true}
                      styling="mb-5"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.country}
                      isInvalid={touched.country && errors.country}
                      errorMessage={errors.country}
                      options={this.state.countries}
                      noSelectionMessage="Please select.."
                      isDisabled={this.props.isDisabled}
                    />
                  </Col>
                </Row>
                <Field
                  id="birthday"
                  name="birthday"
                  component={FormDatePickerWithLabel}
                  value={values.birthday}
                  styling="mb-5"
                  labelValue="Date of birth"
                  required={true}
                  maxDate={this.maxDate}
                  dateFormat={DATE_FORMAT}
                  isDisabled={this.props.isDisabled}
                  isInvalid={touched.birthday && errors.birthday}
                  errorMessage={errors.birthday}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <Row>
                  <Col sm="12" md="6">
                    <FormInputWithLabel
                      id="email"
                      value={values.email}
                      labelValue="E-mail address"
                      required={true}
                      type="input"
                      styling="mb-5"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isInvalid={touched.email && errors.email}
                      errorMessage={errors.email}
                      isDisabled={this.props.isDisabled}
                      infoMessage={BUSINESS_INFO_ENUM.CUSTOMER_EMAIL_ADDRESS}
                    />
                  </Col>

                  {this.props.isDisabled ? (
                    ''
                  ) : (
                    <Col sm="12" md="6">
                      <FormInputWithLabel
                        id="emailRepeat"
                        value={values.emailRepeat}
                        labelValue="Confirm E-mail address"
                        required={true}
                        type="input"
                        styling="mb-5"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isInvalid={touched.emailRepeat && errors.emailRepeat}
                        errorMessage={errors.emailRepeat}
                        isDisabled={this.props.isDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <h5>Legal documents with details about your insurance:</h5>
                <LegalDocumentsComponent className="mt-5 mb-3" />
              </Container>
              <ErrorFocus />
            </Form>
          )}
        </Formik>
      );
    } else if (!userTokenIsValid()) {
      return <NotFound text="You purchase is no longer available." />;
    } else {
      return <div />;
    }
  }
}

export default withTranslation()(PersonalDetails);
