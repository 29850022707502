import { APIFormatHelper } from '@bsurance/bslib/src/utils/APIFormatHelper';

export class Customer extends APIFormatHelper {
  first_name;
  family_name;
  email;
  birthdate;
  phone;

  constructor(personalDetailsView) {
    super();

    this.first_name = personalDetailsView.firstName;
    this.family_name = personalDetailsView.familyName;
    this.email = personalDetailsView.email?.toLowerCase();
    this.phone = personalDetailsView.phone;
    this.birthdate = this.formatDate(personalDetailsView.birthday);
  }
}
