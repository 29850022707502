import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import HorizontalStepper from '@bsurance/bslib/src/components/Stepper/HorizonalStepper';
import history from '../../services/routers/History';
import { ENTER_PERSONAL_DETAILS_SCREEN } from '../../services/routers/Routes';
import { StepperState } from '../../domain/models/states/StepperState';
import StepNavigation from '../StepNavigation/StepNavigation';

class ProcessStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = new StepperState();

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleCurrentStep = this.handleCurrentStep.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleProceed = this.handleProceed.bind(this);
  }

  componentDidMount() {
    this.setState({ id: this.props.match.params.purchaseId });
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (
        urlParams.has('fillForm') &&
        urlParams.get('fillForm') === 'true' &&
        (process.env.REACT_APP_TARGET === 'development' ||
          process.env.REACT_APP_TARGET === 'staging')
      ) {
        this.setState({ initForm: true });
      }
    } catch (error) {
      console.log('ClaimForm ERROR: ', error);
    }
  }

  handleCurrentStep(activeStep) {
    this.setState({ activeStep: activeStep });
  }

  handleNextStep() {
    let activeStep = this.state.activeStep;
    let nextStep = activeStep + 1;

    this.setState({ activeStep: nextStep });
    history.push(
      `${this.state.steps[nextStep].url}${
        this.state.id !== undefined ? `/${this.state.id}` : ''
      }/`
    );
  }

  handleChangeData() {
    this.setState({ activeStep: 0 });
    history.push(
      `${ENTER_PERSONAL_DETAILS_SCREEN.path}/${this.props.match.params.purchaseId}/`
    );
  }

  handleProceed() {
    var activeStep = this.state.activeStep;
    if (!this.state.steps[activeStep].formEnabled) this.handleNextStep();
  }

  render() {
    return (
      <div>
        <Container>
          <Col xs="12" md="12" className="p-0">
            <HorizontalStepper
              steps={this.state.steps.map(element => element.label)}
              activeStep={this.state.activeStep}
            />
            <this.props.component
              fillForm={this.state.initForm}
              onNextStep={this.handleNextStep}
              onCurrentStep={this.handleCurrentStep}
              data={this.props.data}
              match={this.props.match}
              errors={
                this.props.location.state
                  ? this.props.location.state.errors
                  : null
              }
            />
          </Col>
        </Container>
        {this.state.steps[this.state.activeStep].showNavigation && (
          <StepNavigation
            form={this.state.steps[this.state.activeStep].form}
            proceedLabel={this.state.steps[this.state.activeStep].buttonLabel}
            backLabel="Change Data"
            backButtonEnabled={
              this.state.steps[this.state.activeStep].backButtonEnabled
            }
            backCallback={this.handleChangeData}
            proceedCallback={this.handleProceed}
          />
        )}
      </div>
    );
  }
}

export default ProcessStepper;
