import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import React from 'react';
import OeadStore from './components/OeadStore';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faEnvelope,
  faFilePdf,
  faPhone,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

library.add(faArrowRight, faFilePdf, faEnvelope, faPhone, faCheckCircle);

function App() {
  return (
    <div className="App">
      <div id="toasterHelperEntryPoint" />
      <OeadStore />
    </div>
  );
}

export default withTranslation()(App);
