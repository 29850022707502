import baseService from '@bsurance/bslib/src/services/api/BaseService';
import { isUserAuthenticated } from '@bsurance/bslib/src/services/api/AuthenticationService';

const getUserIdFromBaseService = () => {
  return baseService.userResourceId ? baseService.userResourceId : '';
};

export const createUserToken = authentication => {
  return baseService.post(`/users/`, authentication).then(response => {
    if (response.user_id) baseService.setUserResourceId(response.user_id);
  });
};

export const userTokenIsValid = async () => {
  const isAuthenticated = await isUserAuthenticated();

  return isAuthenticated;
};

export const updateUserEmail = userEmail => {
  let userId = getUserIdFromBaseService();
  if (userId) {
    return baseService.patch(`/users/${userId}/`, {
      email: userEmail
    });
  } else {
    return new Promise(function(reject) {
      reject(Error('User email update failed!'));
    });
  }
};
