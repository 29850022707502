const BASE_LANDING_PAGE = 'shop';
const BASE_ENTER_PERSONAL_DETAILS_SCREEN = 'personalDetails';
const BASE_ENTER_PAYMENT_SCREEN = 'payment';
const BASE_SUMMARY_SCREEN = 'summary';
const BASE_SUCCESS_PAGE = 'confirmation';
const BASE_ERROR_PAGE = 'error';
const BASE_LOGIN_CLAIM_PAGE = 'claims';

export const LANDING_PAGE = {
  path: `/${BASE_LANDING_PAGE}/`,
  match: `/${BASE_LANDING_PAGE}`
};
export const IMPRINT_PAGE = {
  path: `/${BASE_LANDING_PAGE}/imprint`,
  match: `/${BASE_LANDING_PAGE}/imprint`
};
export const ENTER_PERSONAL_DETAILS_SCREEN = {
  path: `/${BASE_LANDING_PAGE}/${BASE_ENTER_PERSONAL_DETAILS_SCREEN}`,
  match: `/${BASE_LANDING_PAGE}/${BASE_ENTER_PERSONAL_DETAILS_SCREEN}/:purchaseId`
};
export const ENTER_PAYMENT_SCREEN = {
  path: `/${BASE_LANDING_PAGE}/${BASE_ENTER_PAYMENT_SCREEN}`,
  match: `/${BASE_LANDING_PAGE}/${BASE_ENTER_PAYMENT_SCREEN}/:purchaseId`
};
export const SUMMARY_SCREEN = {
  path: `/${BASE_LANDING_PAGE}/${BASE_SUMMARY_SCREEN}`,
  match: `/${BASE_LANDING_PAGE}/summary/:purchaseId`
};
export const SUCCESS_PAGE = {
  path: `/${BASE_LANDING_PAGE}/${BASE_SUCCESS_PAGE}`,
  match: `/${BASE_LANDING_PAGE}/${BASE_SUCCESS_PAGE}/:purchaseId`
};
export const ERROR_PAGE = {
  path: `/${BASE_LANDING_PAGE}/${BASE_ERROR_PAGE}`,
  match: `/${BASE_LANDING_PAGE}/${BASE_ERROR_PAGE}`
};
export const CLAIM_PAGE = {
  path: `/${BASE_LOGIN_CLAIM_PAGE}`,
  match: `/${BASE_LOGIN_CLAIM_PAGE}`
};
