import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';
import { TYPE_ENUM } from '../../constants/TYPE_ENUM';

export class PersonalDetailsState {
  toaster;
  isLoading;
  personalDetails;
  type;
  countries;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
    this.type = TYPE_ENUM.STUDENT_TRIP;
    this.countries = [];
  }
}
