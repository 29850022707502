import baseService from '@bsurance/bslib/src/services/api/BaseSessionService';
import Cookies from 'js-cookie';

export const getPurchaseById = async purchaseId => {
  return await baseService.get(`/purchases/${purchaseId}/`);
};

export const getPurchaseByIdWithEmbedEntity = async (
  purchaseId,
  embedEntity
) => {
  return await baseService.get(
    `/purchases/${purchaseId}/?embed=${embedEntity}`
  );
};

export const createPurchase = async purchase => {
  return await baseService.post(`/purchases/`, purchase, {
    headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
  });
};

export const updatePurchase = async (purchaseId, purchase) => {
  return await baseService.patch(`/purchases/${purchaseId}/`, purchase, {
    headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
  });
};

export const processPurchase = async purchaseId => {
  return await baseService.put(`/purchases/${purchaseId}/process/`, {
    headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
  });
};

export const linkPurchaseWithQuote = async (purchaseId, quoteId) => {
  return await baseService.put(`/purchases/${purchaseId}/quotes/${quoteId}/`, {
    headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
  });
};

export const validatePurchase = async purchaseId => {
  return await baseService.put(`/purchases/${purchaseId}/validate/`, {
    headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
  });
};
