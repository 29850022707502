import { BaseService } from '../api/BaseService';
import CountriesList from './countries.json';

// For more information - https://restcountries.eu/
export class RestCountries {
  client;

  constructor(baseUrl) {
    let clientConfig = {
      url: baseUrl
    };

    this.client = new BaseService(clientConfig, false);
  }

  getAll(filter, timeout = 5000) {
    return new Promise((resolve, reject) => {
      resolve(CountriesList);
    });
    // 5 seconds timeout has default
    // return this.client.get(this.appendFilter('/all', filter), {
    //   timeout
    // });
  }

  getAll2Code() {
    return this.getAll(['name', 'alpha2Code']);
  }

  getCountry(country2Code, filter) {
    return this.client.get(this.appendFilter(`/alpha/${country2Code}`, filter));
  }

  appendFilter(endpoint, filter) {
    return `${endpoint}${filter ? `?${filter.join(';')}` : ''}`;
  }
}

export default new RestCountries('https://restcountries.eu/rest/v2');
