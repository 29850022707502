import { APIFormatHelper } from '@bsurance/bslib/src/utils/APIFormatHelper';

export class Policy extends APIFormatHelper {
  start_date;
  end_date;

  constructor(personalDetails) {
    super();

    this.start_date = this.formatDate(new Date());
    this.end_date = this.formatDate(personalDetails.travelStartDate);
  }
}
