import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/dist/cookieconsent';

import TagManager from 'react-gtm-module';
import { useEffect, useRef } from 'react';

import { getLocalStorageLanguage } from '../utils/utils';

export default function CookieConsent({ tagManagerArgs, language }) {
  const cookieConsentRef = useRef(null);

  useEffect(() => {
    if (process.env.REACT_APP_TARGET !== 'production') return;
    const cc = window.initCookieConsent();
    TagManager.initialize(tagManagerArgs);

    if (cookieConsentRef.current) {
      cookieConsentRef.current.updateLanguage(language);
    }

    if (!cookieConsentRef.current) {
      cookieConsentRef.current = window.initCookieConsent();
      cookieConsentRef.current.run({
        current_lang: getLocalStorageLanguage(),
        autoclear_cookies: true, // default: false
        //theme_css: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.0/dist/cookieconsent.css',  // 🚨 replace with a valid path
        page_scripts: true, // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: 'browser', // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        remove_cookie_tables: true, // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        gui_options: {
          consent_modal: {
            layout: 'bar', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            transition: 'slide', // zoom/slide
            swap_buttons: false // enable to invert buttons
          },
          settings_modal: {
            layout: 'box', // box/bar
            // position: 'left',           // left/right
            transition: 'slide' // zoom/slide
          }
        },

        onFirstAction: function(user_preferences, cookie) {
          // callback triggered only once
        },

        onAccept: function(cookie) {
          // ... cookieconsent accepted

          // If all both analytics and advertising cookies are allowed, fire the relevant trigger in GTM
          if (
            cc.allowedCategory('analytics') &&
            cc.allowedCategory('advertising')
          ) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'all_cookies' });
          }
          // If Analytics cookies are accepted, fire the relevant trigger in GTM
          else if (cc.allowedCategory('analytics')) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'analytics' });
          }
          // If cookies were rejected, fire the relevant trigger in GTM (necessary cookies)
          else if (cc.allowedCategory('necessary')) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'necessary' });
          }
        },

        languages: {
          en: {
            consent_modal: {
              title: 'Cookie Consent',
              description:
                'This website uses cookies that help the website to function as expected, as well as some Analytical and Performance related cookies. For us to provide the best user experience possible for you as a visitor, enable the specific cookies from the Preferences. <button type="button" data-cc="c-settings" class="cc-link">Cookie Preferences</button>',
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Reject all',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Cookie preferences',
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',
              reject_all_btn: 'Reject all',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Cookie usage',
                  description:
                    'We use cookies in order to improve the user experience for our visitors. If you\'d like to learn more about these cookies, please consult our <a href="https://www.herf.at/datenschutz" class="cc-link">Privacy Policy</a>.'
                },
                {
                  title: 'Strictly necessary cookies',
                  description:
                    'Necessary cookies are absolutely needed for the website to function as expected. This category only contains the cookies that are required to guarantee the basic functionalities and security features of the website. These cookies do NOT store any personal data.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Analytics cookies',
                  description:
                    'Analytics cookies are used to understand how visitors use or interact with the website, as well as key metrics such as number of visitors, traffic source, popular areas on the website, etc. These cookies do NOT store any personal data.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          },
          es: {
            consent_modal: {
              title: 'Consentimiento para el uso de cookies',
              description:
                'Este sitio web utiliza cookies que ayudan al sitio web a funcionar de la forma esperada, así como algunas cookies analíticas y de rendimiento. Para que podamos ofrecerle la mejor experiencia de usuario posible como visitante, habilite las cookies específicas desde las Preferencias. <button type="button" data-cc="c-settings" class="cc-link">Preferencias de cookies</button>',
              primary_btn: {
                text: 'Aceptar todas',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Rechazar todo',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Preferencias de cookies',
              save_settings_btn: 'Guardar ajustes',
              accept_all_btn: 'Aceptar todas',
              reject_all_btn: 'Rechazar todo',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Uso de cookies',
                  description:
                    'Utilizamos cookies para mejorar la experiencia de usuario de nuestros visitantes. Si desea obtener más información sobre estas cookies, consulte nuestra <a href="https://www.herf.at/datenschutz" class="cc-link">Política de privacidad</a>.'
                },
                {
                  title: 'Cookies estrictamente necesarias',
                  description:
                    'Las cookies necesarias son absolutamente necesarias para que el sitio web funcione como se espera. Esta categoría sólo contiene las cookies necesarias para garantizar las funcionalidades básicas y las características de seguridad del sitio web. Estas cookies NO almacenan datos personales.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Cookies analíticas',
                  description:
                    'Las cookies analíticas se utilizan para comprender cómo los visitantes utilizan o interactúan con el sitio web, así como métricas clave como el número de visitantes, la fuente de tráfico, las áreas populares del sitio web, etc. Estas cookies NO almacenan ningún dato personal.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          },
          de: {
            consent_modal: {
              title: 'Cookie-Einwilligung',
              description:
                'Diese Website verwendet Cookies die beitragen, dass die Website wie erwartet funktioniert, sowie einige analytische und leistungsbezogene Cookies. Damit wir Ihnen als Besucher die bestmögliche Benutzererfahrung bieten können, sollten Sie die spezifischen Cookies in den Einstellungen aktivieren. <button type="button" data-cc="c-settings" class="cc-link">Cookie-Einstellungen</button>',
              primary_btn: {
                text: 'Alle akzeptieren',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Alle ablehnen',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Cookie-Einstellungen',
              save_settings_btn: 'Einstellungen speichern',
              accept_all_btn: 'Alle akzeptieren',
              reject_all_btn: 'Alle ablehnen',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Verwendung von Cookies',
                  description:
                    'Wir verwenden Cookies, um die Benutzerfreundlichkeit für unsere Besucher zu verbessern. Wenn Sie mehr über diese Cookies erfahren möchten, lesen Sie bitte unsere <a href="https://www.herf.at/datenschutz" class="cc-link">Datenschutzrichtlinie</a>.'
                },
                {
                  title: 'Notwendige Cookies',
                  description:
                    'Notwendige Cookies sind unbedingt erforderlich, damit die Website wie erwartet funktioniert. Diese Kategorie enthält nur jene Cookies, die erforderlich sind, um die grundlegenden Funktionalitäten und Sicherheitsmerkmale der Website zu gewährleisten. Diese Cookies speichern KEINE persönlichen Daten.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Analytische Cookies ',
                  description:
                    'Analytische Cookies  werden verwendet, um zu verstehen, wie Besucher die Website nutzen oder mit ihr interagieren und um wichtige Kennzahlen, wie die Anzahl der Besucher, Naviagationsprofile, beliebte Bereiche der Website usw. zu ermitteln. Diese Cookies speichern KEINE persönlichen Daten.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          },
          it: {
            consent_modal: {
              title: 'Consenso ai cookie',
              description:
                'Questo sito web utilizza cookie che aiutano il sito a funzionare come previsto, oltre ad alcuni cookie analitici e di performance. Affinché il sito offra la migliore esperienza d\'uso possibile per il visitatore, è necessario attivare i cookie specifici nelle Preferenze. <button type="button" data-cc="c-settings" class="cc-link">Preferenze sui cookie</button>',
              primary_btn: {
                text: 'Accetta tutti',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Rifiuta tutti',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Cookie preferences',
              save_settings_btn: 'Salva le impostazioni',
              accept_all_btn: 'Accetta tutti',
              reject_all_btn: 'Rifiuta tutti',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Utilizzo dei cookie',
                  description:
                    'Utilizziamo i cookie per migliorare l\'esperienza d\'uso dei nostri visitatori. Se desiderate saperne di più su questi cookie, consultate la nostra <a href="https://www.herf.at/datenschutz" class="cc-link">Informativa sulla privacy</a>.'
                },
                {
                  title: 'Cookie strettamente necessari',
                  description:
                    'I cookie necessari sono assolutamente indispensabili affinché il sito web funzioni come previsto. Questa categoria contiene solo i cookie necessari per garantire le funzionalità di base e le caratteristiche di sicurezza del sito web. Questi cookie NON memorizzano alcun dato personale.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Cookie analitici',
                  description:
                    'I cookie analitici sono utilizzati per capire come i visitatori utilizzano o interagiscono con il sito web, oltre che per ottenere dati chiave come il numero di visitatori, la fonte di traffico, le aree più popolari del sito web, ecc. Questi cookie NON memorizzano alcun dato personale.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          },
          fr: {
            consent_modal: {
              title: 'Politique de cookies',
              description:
                'Ce site web utilise des cookies permettant son bon fonctionnement, ainsi que des cookies liés à la performance et l\'analyse de votre navigation. Pour garantir une meilleure expérience utilisateur, activez les cookies spécifiques depuis les Préférences. <button type="button" data-cc="c-settings" class="cc-link">Préférences de Cookies</button>',
              primary_btn: {
                text: 'Accepter tout',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Tout rejeter',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Préférences de Cookies',
              save_settings_btn: 'Sauvegarder les préférences',
              accept_all_btn: 'Accepter tout',
              reject_all_btn: 'Tout rejeter',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Utilisation des cookies',
                  description:
                    'Nous utilisons des cookies afin d\'améliorer l\'expérience utilisateur de nos visiteurs. Si vous souhaitez en savoir plus sur ces cookies, veuillez consulter notre <a href="https://www.herf.at/datenschutz" class="cc-link">Politique de confidentialité</a>.'
                },
                {
                  title: 'Cookies nécessaires',
                  description:
                    'Les cookies nécessaires sont absolument indispensables pour que le site web fonctionne. Cette catégorie ne contient que les cookies qui sont nécessaires pour garantir les fonctionnalités de base et les dispositifs de sécurité du site web. Ces cookies NE STOCKENT PAS de données personnelles.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Cookies analytiques',
                  description:
                    'Les cookies analytiques sont utilisés pour comprendre comment les visiteurs utilisent ou interagissent avec le site web, et permettent de mesurer des données clés telles que le nombre de visiteurs, la source de trafic, les zones populaires du site web, etc... Ces cookies NE STOCKENT PAS de données personnelles.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          },
          nl: {
            consent_modal: {
              title: 'Cookie toestemming',
              description:
                'Deze website gebruikt cookies die de website helpen te functioneren zoals verwacht, evenals enkele analytische en prestatiegerelateerde cookies. Om u als bezoeker de best mogelijke gebruikerservaring te bieden, schakelt u de specifieke cookies uit de Voorkeuren in. <button type="button" data-cc="c-settings" class="cc-link">Cookie voorkeuren</button>',
              primary_btn: {
                text: 'Alles accepteren',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Alles weigeren',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Cookie voorkeuren',
              save_settings_btn: 'Instellingen opslaan',
              accept_all_btn: 'Alles accepteren',
              reject_all_btn: 'Alles weigeren',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Gebruik van cookies',
                  description:
                    'Wij gebruiken cookies om de gebruikerservaring voor onze bezoekers te verbeteren. Als u meer wilt weten over deze cookies, raadpleeg dan onze <a href="https://www.herf.at/datenschutz" class="cc-link">Privacy Policy</a>.'
                },
                {
                  title: 'Strikt noodzakelijke cookies',
                  description:
                    'Noodzakelijke cookies zijn absoluut noodzakelijk om de website te laten functioneren zoals verwacht. Deze categorie bevat alleen de cookies die nodig zijn om de basisfuncties en beveiligingsfuncties van de website te garanderen. Deze cookies slaan GEEN persoonlijke gegevens op.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: 'Analytische cookies',
                  description:
                    'Analytische cookies worden gebruikt om te begrijpen hoe bezoekers de website gebruiken of ermee omgaan, evenals belangrijke statistieken zoals aantal bezoekers, verkeersbron, populaire gebieden op de website, enz. Deze cookies slaan GEEN persoonlijke gegevens op.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...'
                    }
                  ]
                }
              ]
            }
          }
        }
      });
    }

    // cc.run();

    document.getElementById('cc_div').classList.add('c_darkmode');
  }, [language]);

  return null;
}
