import { Policy } from './Policy';
import { APIFormatHelper } from '@bsurance/bslib/src/utils/APIFormatHelper';
import { Customer } from './Customer';
import { PURCHASE_INSTANCE_TYPE_ENUM } from '../../constants/PURCHASE_INSTANCE_TYPE_ENUM';
import { InsuredEntity } from './InsuredEntity';
import { PartialInsuredEntity } from './PartialInsuredEntity';
import { Residence } from './Residence';

export class Purchase extends APIFormatHelper {
  type;
  metadata;
  quote;

  constructor(type, data, instanceType) {
    super();
    this.type = type;
    this.metadata = this.constructMetadata(data, instanceType);
  }

  constructPartialPolicyAndInsuredEntity(quoteOptionsView) {
    return {
      insured_entity: new PartialInsuredEntity(quoteOptionsView)
    };
  }

  constructMetadata(data, instanceType) {
    if (
      instanceType === PURCHASE_INSTANCE_TYPE_ENUM.PARTIAL_INSURED_ENTITY_POLICY
    ) {
      return this.constructPartialPolicyAndInsuredEntity(data);
    } else {
      return {
        customer: new Customer(data),
        residence: new Residence(data),
        policy: new Policy(data),
        insured_entity: new InsuredEntity(data)
      };
    }
  }
}
