import { DATE_CONSTANTS } from '@bsurance/bslib/src/domain/constants/DATE_CONSTANTS';
import {
  MAX_AGE,
  MIN_AGE,
  MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE
} from '../../../domain/constants/CONSTANTS';
import { PhonenumberValidationSchema } from '@bsurance/bslib/src/components/YupValidation/PhonenumberValidationSchema';
import { EmailAndEmailRepeatValidationSchema } from '@bsurance/bslib/src/components/YupValidation/EmailAndEmailRepeatValidationSchema';

export const PersonalDetailsValidationSchema = t => {
  let yup = require('yup');

  return yup.object().shape({
    isHousingDeposit: yup
      .boolean()
      .test(
        'isHousingDepositOrCancellationCostTrue',
        'This field is required!',
        function(isHousingDeposit) {
          // @ts-ignore
          return !(!isHousingDeposit && !this.parent.isCancellationCost);
        }
      ),
    isCancellationCost: yup
      .boolean()
      .test(
        'isCancellationCostOrHousingDeposit',
        'This field is required',
        function(isCancellationCost) {
          // @ts-ignore
          return !(!isCancellationCost && !this.parent.isHousingDeposit);
        }
      ),
    travelStartDate: yup
      .date()
      .typeError('Please enter a correct date')
      .required('This field is required!')
      .test(
        'Travel start date',
        `You can only purchase an insurance ${MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE} days before your travel`,
        function(value) {
          let ageDiffMs = value - new Date().getDate();
          let days = Math.floor(
            ageDiffMs / DATE_CONSTANTS.ONE_DAY_IN_MILLISECONDS
          );
          return days >= MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE;
        }
      ),
    rentalAgreementStartDate: yup
      .date()
      .typeError('Please enter a correct date')
      .required('This field is required!'),
    firstName: yup.string().required('This field is required!'),
    familyName: yup.string().required('This field is required!'),
    birthday: yup
      .date()
      .typeError('Please enter a correct date')
      .required('This field is required!')
      .test(
        'Date of Birth',
        'You need to be over the age of 18 years',
        function(value) {
          let ageDifMs = Date.now() - value;
          let age = Math.floor(
            ageDifMs / DATE_CONSTANTS.ONE_YEAR_IN_MILLISECONDS
          );
          return age >= MIN_AGE;
        }
      )
      .test(
        'Date of Birth',
        'This insurance product is not meant for someone your age',
        function(value) {
          let ageDifMs = Date.now() - value;
          let age = Math.floor(
            ageDifMs / DATE_CONSTANTS.ONE_YEAR_IN_MILLISECONDS
          );
          return age <= MAX_AGE;
        }
      ),
    ...PhonenumberValidationSchema(t).fields,
    country: yup.string().required('This field is required!'),
    ...EmailAndEmailRepeatValidationSchema(t).fields
  });
};
