import React from 'react';
import { Link } from 'react-router-dom';
import { LANDING_PAGE } from '../services/routers/Routes';

const ErrorPage = props => {
  return (
    <div className="p-5">
      <div className="display-4 md:display-1 mt-2 mb-5 font-weight-bolder text-center">
        There was an error we couldn't handle properly!
      </div>

      {props.children || (
        <div className="text-center">
          <p>
            Please send an email to this address:{' '}
            <a href="mailto:insurance@oeadstudenthousinginsurance.com?subject=Unhandled error displayed">
              insurance@oeadstudenthousinginsurance.com
            </a>
          </p>
          <p>If possible, state the flow you did to reach this page.</p>

          <Link to={LANDING_PAGE.path}>Go Home</Link>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
