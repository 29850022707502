import {
  LANDING_PAGE,
  ENTER_PERSONAL_DETAILS_SCREEN,
  SUMMARY_SCREEN,
  SUCCESS_PAGE,
  ENTER_PAYMENT_SCREEN
} from '../../services/routers/Routes';

export const PROGRESS_STEPPER_STEPS_ENUM = {
  QUOTE: 0,
  PERSONAL_DETAILS: 1,
  SUMMARY: 2,
  PAYMENT: 3,
  SUCCESS: 4
};

export const STEPS = [
  {
    stepId: PROGRESS_STEPPER_STEPS_ENUM.QUOTE,
    formEnabled: true,
    form: 'quote',
    label: 'Calculate your price',
    buttonLabel: 'Next',
    backButtonEnabled: false,
    url: LANDING_PAGE.path,
    showNavigation: false
  },
  {
    stepId: PROGRESS_STEPPER_STEPS_ENUM.PERSONAL_DETAILS,
    formEnabled: true,
    form: 'personalDetails',
    label: 'My Information',
    buttonLabel: 'Next',
    backButtonEnabled: false,
    url: ENTER_PERSONAL_DETAILS_SCREEN.path,
    showNavigation: true
  },
  {
    stepId: PROGRESS_STEPPER_STEPS_ENUM.SUMMARY,
    formEnabled: true,
    form: 'summary',
    label: 'Summary',
    buttonLabel: 'Proceed to payment',
    backButtonEnabled: true,
    url: SUMMARY_SCREEN.path,
    showNavigation: true
  },
  {
    stepId: PROGRESS_STEPPER_STEPS_ENUM.PAYMENT,
    formEnabled: false,
    form: '',
    label: 'Payment',
    buttonLabel: 'Next',
    backButtonEnabled: false,
    url: ENTER_PAYMENT_SCREEN.path,
    showNavigation: false
  },
  {
    stepId: PROGRESS_STEPPER_STEPS_ENUM.SUCCESS,
    formEnabled: false,
    form: 'success',
    label: 'Thank You',
    buttonLabel: 'Done',
    backButtonEnabled: false,
    url: SUCCESS_PAGE.path,
    showNavigation: false
  }
];
