import history from './routers/History';
import axios from 'axios';
import { LANDING_PAGE } from './routers/Routes';

export const formatDate = date => {
  return date.toISOString().slice(0, 10);
};

export const redirectByPurchaseStatus = (
  purchase,
  toaster,
  activeStatusUrl,
  failedStatusUrl
) => {
  if (activeStatusUrl && purchase.status === 'active') {
    history.push(activeStatusUrl);
    toaster.showWarning(
      "You can't view or edit previously purchased insurances."
    );
  } else if (failedStatusUrl && purchase.status === 'failed') {
    history.push(failedStatusUrl);
  }
};

export const manageApiError = (error, toaster) => {
  if (
    axios.isAxiosError(error) &&
    error.response?.status === 404 &&
    JSON.stringify(error.response?.data?.purchase_id) ===
      '"Resource does not exist."'
  ) {
    toaster.showDanger(
      'Your session has timed out. Please re-start purchase process'
    );
  } else if (axios.isAxiosError(error) && error.response?.status !== 500) {
    toaster.showDanger(JSON.stringify(error.response?.data));
  } else {
    toaster.showGenericError();

    history.push(`${LANDING_PAGE.path}/`);
  }
};
