import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLAIM_PAGE, LANDING_PAGE } from '../services/routers/Routes';

const Footer = () => (
  <footer className="font-small pt-4">
    <div className="container text-center text-md-left">
      <div className="row">
        <div className="col-md-6 mt-md-0 mt-6">
          <h6 className="mb-2 md:mb-4">
            <b>USEFUL LINKS</b>
          </h6>
          <ul className="list-unstyled">
            <li>
              <Link to={LANDING_PAGE.path}>Home</Link>
            </li>
            <li>
              <a href={CLAIM_PAGE.path}>Report a Claim</a>
            </li>
            <li>
              <Link to="/shop/imprint">Imprint</Link>
            </li>
          </ul>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0" />

        <div className="col-md-6 mb-md-0 mb-6">
          <h6 className="mb-2 md:mb-4">
            <b>CONTACTS</b>
          </h6>
          <ul className="list-unstyled small">
            <li>Michael Herf GmbH, Insurance agency</li>
            <li>General agency of UNIQA Austria Insurance AG</li>
            <li>FN: 321569 x, Commercial Court Vienna</li>
            <li>Austria - 1010 Vienna, Bäckerstraße 7/6</li>
            <li>
              <FontAwesomeIcon icon="envelope" size="1x" className="mr-1" />
              <a href="mailto:office.herf@uniqa.at">office.herf@uniqa.at</a> |
              <FontAwesomeIcon icon="phone" size="1x" className="mx-1" />
              <a href="tel:004315139613">+43 1 513 96 13</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
