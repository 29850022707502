import * as React from 'react';
import { Form, FormControl, FormLabel, InputGroup } from 'react-bootstrap';
import InfoWrapper from './InfoWrapper';

/**
 * @deprecated
 * USE MyFormInput.tsx
 */
const FormInputWithLabel = props => {
  const {
    value,
    placeholder,
    labelValue,
    id,
    styling,
    type,
    handleChange,
    handleBlur,
    as,
    isInvalid,
    errorMessage,
    isDisabled,
    infoMessage,
    rows,
    secondaryLabel,
    required
  } = props;

  return (
    <div className={styling}>
      {labelValue && (
        <FormLabel className="font-weight-bold" htmlFor={id}>
          {required && !isDisabled ? labelValue + ' *' : labelValue}

          {infoMessage && !isDisabled ? (
            <InfoWrapper message={infoMessage} />
          ) : null}
        </FormLabel>
      )}
      {secondaryLabel ? (
        <FormLabel className="d-block" htmlFor={id}>
          {secondaryLabel}
        </FormLabel>
      ) : null}
      <InputGroup>
        <FormControl
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type={type}
          as={as}
          isInvalid={isInvalid}
          disabled={isDisabled}
          rows={rows}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default FormInputWithLabel;
