import { TFunction } from 'i18next';

export const EmailAndEmailRepeatValidationSchema = t => {
  let yup = require('yup');

  return yup.object().shape({
    email: yup
      .string()
      .email(t('common:validation.email-invalid'))
      .required(t('common:validation.required')),
    emailRepeat: yup
      .string()
      .email(t('common:validation.email-invalid'))
      .oneOf(
        [yup.ref('email'), null],
        t('common:validation.email-no-match-email-repeat')
      )
      .required(t('common:validation.required'))
  });
};
