import { ToasterClass } from '@bsurance/bslib/src/domain/models/states/ToasterState';
import { STEPS } from '../../constants/PROGRESS_STEPPER_STEPS_ENUM';

export class StepperState {
  activeStep;
  steps;
  isLoading;
  toaster;
  id;
  errors;

  constructor() {
    this.toaster = new ToasterClass();
    this.isLoading = false;
    this.activeStep = 0;
    this.steps = STEPS;
    this.id = 0;
    this.errors = null;
  }
}
