import * as React from 'react';
import { Form, FormControl, FormLabel, InputGroup } from 'react-bootstrap';

const FormSelectWithLabel = props => {
  const {
    id,
    value,
    labelValue,
    subLabelValue,
    styling,
    handleChange,
    handleBlur,
    isInvalid,
    errorMessage,
    options,
    noSelectionMessage,
    isDisabled,
    required
  } = props;

  return (
    <div className={styling}>
      {labelValue && (
        <FormLabel className="font-weight-bold" htmlFor={id}>
          {required && !isDisabled ? labelValue + ' *' : labelValue}
          {subLabelValue && (
            <small className="mb-0 form-text text-muted">{subLabelValue}</small>
          )}
        </FormLabel>
      )}
      <InputGroup>
        <FormControl
          as="select"
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={isInvalid}
          disabled={isDisabled}
          // defaultValue={value}
        >
          <option key="0" value="">
            {noSelectionMessage}
          </option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.display}
            </option>
          ))}
        </FormControl>
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default FormSelectWithLabel;
