import toast from '../../../components/Notification/ToasterComponent';

export class ToasterClass {
  async showDanger(errorMessage) {
    toast.error(errorMessage);
  }

  async showSuccess(errorMessage) {
    toast.success(errorMessage);
  }
  async showWarning(errorMessage) {
    toast.warning(errorMessage);
  }
  async showInfo(errorMessage) {
    toast.info(errorMessage);
  }
  async showGenericError(t) {
    this.showDanger(
      t
        ? t('messages.generic_error')
        : 'Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es etwas später erneut.'
    );
  }
}
