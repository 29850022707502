import { CommonDetailsView } from './CommonDetailsView';
import { MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE } from '../../constants/CONSTANTS';

export class PersonalDetailsView extends CommonDetailsView {
  isCancellationCost;
  isHousingDeposit;
  travelStartDate;
  rentalAgreementStartDate;
  firstName;
  familyName;
  birthday;
  phone;
  country;
  email;
  emailRepeat;

  constructor(purchase = Object()) {
    super();
    this.travelStartDate = new Date();
    this.travelStartDate.setDate(
      new Date().getDate() + MIN_DAYS_IN_THE_FUTURE_FOR_TRAVEL_START_DATE
    );
    this.populateData(purchase);
  }

  populateData(purchase) {
    if (
      purchase.metadata &&
      purchase.metadata.customer &&
      purchase.metadata.insured_entity
    ) {
      let insuredEntity = purchase.metadata.insured_entity;

      this.rentalAgreementStartDate = this.setDate(
        insuredEntity.rental_start_date
      );
      this.travelStartDate = this.setDate(purchase.metadata.policy?.end_date);
      this.firstName = this.setGenericValue(
        purchase.metadata.customer.first_name,
        this.firstName
      );
      this.familyName = this.setGenericValue(
        purchase.metadata.customer.family_name,
        this.familyName
      );
      this.birthday = this.setDate(purchase.metadata.customer.birthdate);
      this.phone = this.setGenericValue(
        purchase.metadata.customer.phone,
        this.phone
      );
      this.country = this.setGenericValue(
        purchase.metadata.residence?.country,
        this.country
      );
      this.email = this.setGenericValue(
        purchase.metadata.customer.email,
        this.email
      )?.toLowerCase();
      this.emailRepeat = this.setGenericValue(
        purchase.metadata.customer.email,
        this.emailRepeat
      );
    }
  }
}
