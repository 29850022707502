import React from 'react';
import './StepNavigation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'react-bootstrap';
import { faArrowRight, faUndo } from '@fortawesome/free-solid-svg-icons';

const StepNavigation = props => {
  return (
    <Container className="bottom-button pt-5">
      <button
        form={props.form}
        onClick={props.proceedCallback}
        type="submit"
        className="btn btn-primary float-right"
        id="forward-button"
      >
        <span>
          <span>{props.proceedLabel}</span>
          <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </span>
      </button>
      {props.backButtonEnabled && (
        <button
          onClick={props.backCallback}
          className="btn btn-primary float-right mr-1"
        >
          <span>
            <span>{props.backLabel}</span>
            <FontAwesomeIcon icon={faUndo} className="ml-3" />
          </span>
        </button>
      )}
    </Container>
  );
};

export default StepNavigation;
